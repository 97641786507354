<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="费用单" width="960px">

    <el-button type="primary" icon="el-icon-plus" @click="add" v-if="editable">添加费用</el-button>

    <el-table row-key="id" :data="data">
      <el-table-column prop="projectName" label="收费类型" width="160" />
      <el-table-column prop="payMoney" label="收费金额" align="right" width="150" :formatter="$price" />
      <el-table-column label="付款期限" width="150" :formatter="r => {return new Date(r.payTime).format('yyyy/MM/dd');}" />
      <el-table-column prop="info" label="备注" show-overflow-tooltip min-width="240" />
      <el-table-column label="状态" width="80" align="center">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="100px" v-if="editable">
        <div class="row-commands" slot-scope="scope">
          <template v-if="scope.row.status === 'edit'">
            <el-button size="mini" type="text" @click="detail(scope.row)">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本收费单吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="doDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </template>
          <template v-else>
            <el-button size="mini" type="text" @click="detail(scope.row)">查看</el-button>

            <el-popover :ref="scope.row.id" placement="top" width="180" v-if="scope.row.status === 'unpay'">
              <p>确定取消本收费单吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="doCancel(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">取消</el-button>
            </el-popover>
          </template>
        </div>
      </el-table-column>
    </el-table>
    
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" @complete="toQuery" />
  </el-dialog>
</template>

<script>
import { del, cancel } from "@/api/cost";
import initData from "@/mixins/initData";
import eForm from "@/views/finance/cost/manage/form";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      dialog: false,
      form: null,
      editable: false,
      status: {
        edit: {
          type: "info",
          label: "编辑中"
        },
        unpay: {
          type: "warning",
          label: "侍支付"
        },
        pay: {
          type: "primary",
          label: "已支付"
        },
        paySuccess: {
          type: "success",
          label: "支付成功"
        },
        payFailed: {
          type: "error",
          label: "支付失败"
        },
        cancel: {
          type: "info",
          label: "已取消"
        }
      }
    };
  },
  methods: {
    beforeInit() {
      if (this.form) {
        this.url = "api/orderBackForm/bill/" + this.form.id;
        this.params = {
          sort: "createAt,asc",
          isDeleted: false
        };
        return true;
      } else {
        this.data = [];
        return false;
      }
    },
    afterLoad(res) {
      (res || []).forEach(o => {
        o._special = true;
      });
    },
    add() {
      if (!this.form) return false;
      this.$refs.form &&
        this.$refs.form.resetForm(null, {
          _special: true,
          orderFormId: this.form.id,
          friendEntId: this.form.buyerId,
          friendEntName: this.form.buyerName
        });
    },
    detail(row) {
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(row)));
    },
    doDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除收费单成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
        });
    },
    doCancel(id) {
      this.delLoading = true;
      cancel(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$notify({
            title: "取消收费单成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
        });
    },
    resetForm(order, editable) {
      if (order) {
        this.form = order;
        this.editable = editable;
        this.dialog = true;
        this.toQuery();
      }
    }
  }
};
</script>