<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" width="700px" @closed="handleClosed">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":">
          <div class="h sb">
            <el-form-item prop="friendEntId" label="往来单位" v-if="!form._special">
              <quick-select v-model="form.friendEntId" filterable :label.sync="form.friendEntName" value-field="enterpriseId" url="api/distributor" auto-select-first-option placeholder="请选择往来单位" style="width: 230px;" />
            </el-form-item>
            <el-form-item prop="projectId" label="收费类型">
              <quick-select v-model="form.projectId" filterable url="api/costBill/payProject" :label.sync="form.projectName" auto-select-first-option placeholder="请选择收费类型" style="width: 230px;" />
            </el-form-item>
          </div>
          <div class="h">
            <el-form-item prop="payMoney" label="收费金额">
              <price-input v-model="form.payMoney" :min="0" :max="999999999" :step-ratio="10000" style="width: 230px" />
            </el-form-item>
            <el-form-item prop="payTime" label="付费期限" label-width="116px">
              <el-date-picker type="date" v-model="form.payTime" value-format="timestamp" :clearable="false" style="width: 230px" />
            </el-form-item>
          </div>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="200" :rows="3" resize="none"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button type="primary" :disabled="submiting" :loading="saving" @click="doSave">保存</el-button>
          <el-button type="danger" :disabled="saving" :loading="submiting" @click="doSubmit" v-if="form.id">保存并提交</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="66px" label-position="right" label-suffix=":">
          <div class="h s sb">
            <div style="width: 50%; margin-right: 30px;">
              <form-info-item label="往来单位">{{form.friendEntName}}</form-info-item>
              <form-info-item label="记账类型">{{form.projectName}}</form-info-item>
              <form-info-item label="收费金额" inline>
                <b class="fs-large fc-s">{{$price(form.payMoney)}}</b>
              </form-info-item>
              <form-info-item label="付费期限">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
              <form-info-item label="支付账户" v-if="form.payerAccount">{{form.payerAccount}}</form-info-item>
              <form-info-item label="收款账户" v-if="form.payeeAccount">{{form.payeeAccount}}</form-info-item>
            </div>
            <div class="flex">
              <form-info-item label="记账单号">{{form.code}}</form-info-item>
              <form-info-item label="ERP单号" v-if="form.erpCode !== form.code">{{form.erpCode}}</form-info-item>
              <form-info-item label="创建人">{{form.createBy}}</form-info-item>
              <form-info-item label="创建时间">{{new Date(form.createAt).format()}}</form-info-item>
              <form-info-item label="当前状态">
                <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
              </form-info-item>
            </div>
          </div>
          <form-info-item label="备注">{{form.info}}</form-info-item>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { add, edit, submit } from "@/api/cost";

export default {
  data() {
    return {
      dialog: false,
      form: null,
      saving: false,
      submiting: false,
      rules: {
        friendEntId: [{ required: true, message: "请选择经销商" }],
        payTime: [{ required: true, message: "请选择收款期限" }],
        payMoney: [{ required: true, message: "请填写收款金额" }],
        projectId: [{ required: true, message: "请选择收费类型" }]
      },
      status: {
        edit: {
          type: "info",
          label: "编辑中"
        },
        unpay: {
          type: "warning",
          label: "侍支付"
        },
        pay: {
          type: "primary",
          label: "已支付"
        },
        paySuccess: {
          type: "success",
          label: "支付成功"
        },
        payFailed: {
          type: "error",
          label: "支付失败"
        },
        cancel: {
          type: "info",
          label: "已取消"
        }
      }
    };
  },
  computed: {
    title() {
      if (this.form) {
        return this.editable
          ? this.form.id
            ? "编辑收费单"
            : "新建收费单"
          : "查看收费单";
      }
      return "加载中…";
    },
    editable() {
      return this.form && this.form.status === "edit";
    }
  },
  methods: {
    handleClosed() {
      this.form = null;
    },
    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.saving = true;
          (this.form.id ? edit : add)(this.form)
            .then(res => {
              if (res) Object.assign(this.form, res);
              if (typeof callback === "function") {
                callback();
              } else {
                this.$notify({
                  title: "保存收费单成功",
                  type: "success",
                  duration: 2500
                });
                this.form.id = res.id;
                if (this.form._special) {
                  this.$emit("complete");
                } else {
                  this.$parent.init();
                }
              }
            })
            .finally(_ => {
              this.saving = false;
            });
        });
    },
    doSubmit() {
      this.$confirm(
        "提交收费单后将不能再进行修改，确定要提交吗？",
        "提交确认",
        {
          type: "warning"
        }
      ).then(res => {
        this.doSave(_ => {
          this.submiting = true;
          submit(this.form.id)
            .then(res => {
              this.$notify({
                title: `提交收费单成功`,
                type: "success",
                duration: 2500
              });
              this.dialog = false;
              if (this.form._special) {
                this.$emit("complete");
              } else {
                this.$parent.init();
              }
            })
            .finally(_ => {
              this.submiting = false;
            });
        });
      });
    },
    resetForm(form, extend) {
      let now = new Date();
      let f =
        form ||
        Object.assign(
          {
            friendEntId: null,
            friendEntName: "",
            payMoney: 0,
            payTime: new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate() + 7
            ).getTime(),
            status: "edit",
            projectId: null,
            projectName: "",
            info: ""
          },
          extend || {}
        );
      if (!f.hasOwnProperty("_special")) f._special = false;
      this.form = f;
      this.dialog = true;
    }
  }
};
</script>